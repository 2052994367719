<template>
  <div class="project-teach-data">
    <div class="teach-data-wrapper">
      <div class="teach-data-main">
        <h3 class="title">教学数据</h3>
        <div class="data-main-content">
          <!-- 工具栏 -->
          <div class="toolbar flex-align-between">
            <p class="course-name nowrap-text">{{ CourseCount.name ||'--' }}</p>
            <div class="toolbar-right">
              <span class="select-label">选择课节：</span>
              <el-select class="selected" v-model="selectLessonId" clearable filterable placeholder="选择课节" size="mini"
                collapse-tags @change="lessonChange">
                <el-option v-for="item in lessonList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <button class="download-xls" @click="downloadExcel">下载表格</button>
              <button class="refresh csp" style="margin-left:15px" @click="refresh">
                <i class="el-icon-refresh"></i>刷新数据</button>
            </div>
          </div>
          <!-- 统计数据 -->
          <ul class="data-count flex-align-between"
            :style="{ justifyContent : $route.query.type == 1 || $route.query.type == 4 ? 'space-around':''}">
            <li class="data-item" v-for="(item,index) in dataList" :key="index"
              v-show="item.type.includes(Number($route.query.type))">
              <!-- <p class="name flex-align">{{item.name}}</p> -->
              <p class="name flex-align">
                {{item.title | handleCountData($route.query.type)}}</p>
              <p class="count">{{item.count}}<i class="unit"
                  v-if="item.title !== 'averageLearn'&&item.title !== 'avgClassRate'">/{{item.unit}}</i>
              </p>
            </li>
          </ul>
          <!-- 数据表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column type="index" label="序号" align="center" style="background:#2373EC" fixed>
                <template slot-scope="scope">
                  {{scope.$index | countIndex(pages)}}
                </template>
              </el-table-column>
              <el-table-column prop="lessonId" label="课节编号" align="center"></el-table-column>
              <el-table-column prop="lesson" label="课节名称" width="250" align="center"></el-table-column>
              <el-table-column prop="userName" label="用户名" width="150" align="center" fixed></el-table-column>
              <el-table-column prop="user" label="学员姓名" width="150" align="center" fixed></el-table-column>

              <el-table-column prop="phone" label="手机号" width="150" align="center" v-if="$route.query.type == 5">
              </el-table-column>

              <el-table-column prop="schoolName" label="单位" width="150" align="center" v-if="$route.query.type == 5">
              </el-table-column>

              <el-table-column prop="isAttendance" label="是否出勤" align="center" v-if="$route.query.type != 1">
                <template slot-scope="scope">
                  {{ scope.row.isAttendance == 0?'否':'是' }}
                </template>
              </el-table-column>

              <el-table-column prop="secondFormat" label="观看时长" align="center" width="120"
                v-if="$route.query.type == 1"></el-table-column>

              <!-- <el-table-column prop="secondFormat" label="实际上课时长" width="130" align="center"
                v-if="$route.query.type != 1">
              </el-table-column> -->

              <el-table-column prop="actualListenTime" label="直播时长" width="130" align="center"
                v-if="$route.query.type != 1">
              </el-table-column>

              <el-table-column prop="learnTime" label="回放时长" width="130" align="center"
                v-if="$route.query.type != 1">
              </el-table-column>

              <el-table-column prop="totalRaise" label="举手次数" align="center"
                v-if="$route.query.type != 1 && $route.query.type != 4  && $route.query.type != 5">
                <template slot-scope="scope">
                  {{ scope.row.totalRaise ? scope.row.totalRaise: 0 }}
                </template>
              </el-table-column>

              <el-table-column prop="totalSpeak" label="实际发言次数" width="130" align="center" sortable
                v-if="$route.query.type != 1 && $route.query.type != 4 && $route.query.type != 5">
                <template slot-scope="scope">
                  {{ scope.row.totalSpeak ? scope.row.totalSpeak: 0 }}
                </template>
              </el-table-column>

              <el-table-column prop="totalQuestion" align="center" label="提问次数"
                v-if="$route.query.type != 1 && $route.query.type != 4 && $route.query.type != 5">
                <template slot-scope="scope">
                  {{ scope.row.totalQuestion ? scope.row.totalQuestion: 0 }}
                </template>
              </el-table-column>

              <el-table-column align="center" width="100" label="观看来源" v-if="$route.query.type != 5">
                <template slot-scope="scope">
                  {{ scope.row.clientType == 0?'PC网页':scope.row.clientType == 1?'pc客户端':scope.row.clientType == 2?'m站':scope.row.clientType == 3?'ios':scope.row.clientType == 4?'android ':scope.row.clientType == 5?'mac客户端':scope.row.clientType == 6?'微信小程序':'--' }}
                </template>
              </el-table-column>

              <el-table-column prop="totalSign" label="签到次数" align="center"
                v-if="$route.query.type != 1 && $route.query.type != 4 && $route.query.type != 5">
                <template slot-scope="scope">
                  {{ scope.row.totalSign ? scope.row.totalSign: 0 }}
                </template>
              </el-table-column>

              <el-table-column prop="totalQuestion" label="答题次数" align="center" v-if="$route.query.type == 4">
              </el-table-column>

              <el-table-column prop="totalWatch" label="观看次数" align="center" v-if="$route.query.type == 1">
              </el-table-column>

              <el-table-column prop="totalChat" label="聊天次数" align="center"
                v-if="$route.query.type != 1 && $route.query.type != 5">
              </el-table-column>

              <el-table-column prop="inLivingRoom" label="进入直播间时间" width="150" align="center"
                v-if="$route.query.type == 5">
              </el-table-column>

              <el-table-column prop="outLivingRoom" label="退出直播间时间" width="150" align="center"
                v-if="$route.query.type == 5">
              </el-table-column>

              <!-- <el-table-column prop="totalChat" label="互动交流次数" width="110" align="center" v-if="$route.query.type == 5">
              </el-table-column> -->

              <el-table-column prop="speakCount" label="连麦次数" align="center" v-if="$route.query.type == 5">
              </el-table-column>

              <el-table-column prop="isTesting" label="是否参与检测" width="120" align="center" v-if="$route.query.type == 5">
              </el-table-column>

              <el-table-column prop="classRate" label="上课率" width="100" align="center" v-if="$route.query.type == 5">
                <template slot-scope="scope">
                  {{`${(scope.row.classRate*100).toFixed(2)}%`}}
                </template>
              </el-table-column>
            </el-table>
            <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
              :limit.sync="pages.pageSize" :pageSizes="[8,16,24]" @pagination="handlePageChange">
            </pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import axios from 'axios'
  export default {

    data() {
      return {
        dataList: [{
            title: 'averageLearn',
            iconClass: 'data-time',
            name: '平均上课时长',
            count: 0,
            unit: '',
            type: [1, 2, 3, 4, 5]
          },
          {
            title: 'totalSign',
            iconClass: 'data-signin',
            name: '课程签到人次',
            count: 0,
            unit: '人',
            type: [2]
          },
          {
            title: 'totalWatch',
            iconClass: 'data-number',
            name: '出勤人数',
            count: 0,
            unit: '人',
            type: [1, 2, 3, 4]
          },
          // {
          //   title: 'totalWatch',
          //   iconClass: 'data-signin',
          //   name: '课程上台人次',
          //   count: 1256,
          //   unit: '人',
          //   type: [4]
          // },
          {
            title: 'totalActive',
            iconClass: 'data-activity',
            name: '课程活跃人数',
            count: 0,
            unit: '人',
            type: [2, 3, 4, 5]
          },
          {
            title: 'avgClassRate',
            iconClass: 'data-activity',
            name: '课程上课率',
            count: 0.00,
            unit: '',
            type: [5]
          },
        ],
        pages: { //分页
          currentPage: 1,
          pageSize: 8,
          total: 0,
        },
        courseInfo: {}, //课程数据
        selectLessonId: null,
        CourseCount: {},
        lessonList: [],
        tableData: [],
      };
    },
    filters: {
      // 统计数据文字对照
      handleCountData(value, type) {
        // console.log(value)
        let contrastChart = {
          averageLearn: type == 1 ? '平均观看时长' : '平均上课时长',
          totalSign: '课程签到人次',
          totalWatch: type == 1 ? '课程观看次数' : "出勤人数",
          totalActive: type == 5 ? '出勤人数' : '课程活跃人数',
          avgClassRate: '课程上课率'
        }

        return contrastChart[value]
      },
      // 计算序号
      countIndex(index, pages) {
        let pageIndex = index + 1;
        if (pages.currentPage == 1) {
          return pageIndex;
        } else {
          return pages.pageSize * (pages.currentPage - 1) + pageIndex
        }
      }
    },
    methods: {
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        // console.log(this.pages);
        this.getTeachTable();
      },
      // 刷新课程数据
      async refresh() {
        let params = {
          courseId: this.$route.params.id,
        }
        let resData = await this.$Api.Course.refreshTeachingData(params);
        console.log(resData);
        if (resData.code == 200) {
          this.getCountData();
          this.getTeachTable();
          this.$message({
            message: '刷新成功',
            type: 'success'
          });
          return;
        }
        this.$message({
          message: `刷新失败${resData.msg}`,
          type: 'error'
        });
      },
      // 获取统计数据
      async getCountData() {
        let params = {
          courseId: this.$route.params.id,
          lessonId: this.selectLessonId
        }
        let resData = await this.$Api.Course.getCourseCount(params);
        console.log(resData);
        if (resData.code === 200) {
          this.CourseCount = resData.data;
          this.dataList.map(item => {
            item.count = resData.data[item.title]
            if (item.title == 'avgClassRate') {
              item.count = `${(item.count*100).toFixed(2)}%`
            }
          })
        }
      },
      // 变更课节
      lessonChange(value) {
        // console.log(value);
        this.getTeachTable();
        this.getCountData();
      },
      // 获取表格数据
      async getTeachTable() {
        let params = {
          courseId: this.$route.params.id,
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
        };
        params.lessonId = this.selectLessonId ? this.selectLessonId : null;
        let resData = await this.$Api.Course.listLessonCount(params);
        // console.log(resData)
        if (resData.code === 200) {
          this.tableData = resData.data.list;
          this.pages.total = resData.data.totalCount;
        }
      },
      // 获取课程信息
      async getCourseInfo() {
        let courseID = this.$route.params.id
        let resData = await this.$Api.Course.getCourseInfo(courseID)
        // console.log(resData);
        this.courseInfo = resData.data
      },
      // 获取课节列表
      async getLessonList() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.getLessonList(id);
        // console.log(resData);
        resData.data.forEach((element, index) => {
          this.lessonList.push({
            label: element.name,
            value: element.id
          });
        });
        // console.log(this.lessonList);
      },
      downloadExcel() {
        window.location.href = axios.defaults.baseURL + "/course/teach/lessonCount/export?courseId=" + this.$route
          .params.id
      }

    },
    mounted() {
      this.getCourseInfo();
      this.getTeachTable();
      this.getCountData();
      this.getLessonList();
      // setTimeout(()=>{
      //   this.showGuide = true
      // },10000)
    },
  };
</script>

<style lang="less" scoped>
  @import "../less/table.less";

  .project-teach-data {
    // min-height: 100vh;

    .teach-data-wrapper {
      width: 990px;
      margin: 0 auto 77px;
      box-shadow: 0px 2px 10px 0px #F5F8FA;
      // flex: 1;
    }

    .bread-crumb {
      line-height: 80px;
    }

    .teach-data-main {
      background-color: #fff;
      border-radius: 8px;
    }

    .title {
      position: relative;
      font-size: 15px;
      // font-weight: bold;
      color: #508EF9;
      line-height: 40px;
      border-bottom: 1px solid #F2F2F2;
      padding-left: 70px;

      &::after {
        content: ' ';
        position: absolute;
        left: 83px;
        bottom: 0;
        width: 35px;
        height: 4px;
        background: #508EF9;
        border-radius: 2px;
      }
    }

    .data-main-content {
      padding: 12px 60px 20px 55px;
    }

    .toolbar {
      background-color: rgba(191, 191, 191, .1);
      padding: 0 20px;
      line-height: 42px;

      .course-name {
        color: #666;
        font-weight: bold;
        width: 400px;
      }

      .refresh {
        &:hover {
          color: #666;
        }
      }


      .select-label {
        font-size: 12px;
        color: #999;
      }

      .selected {
        /deep/ .el-input__inner {
          border-radius: 25px;
          line-height: 25px;
          height: 25px;
        }
      }

      .download-xls {
        margin-left: 35px;
        font-size: 12px;
        color: #666;
      }
    }

    .data-count {
      margin-top: 17px;

      .data-item {
        width: 172px;
        height: 84px;
        background: linear-gradient(90deg, #956FFC 0%, #FB5DA1 100%);
        border-radius: 8px;
        position: relative;
        padding: 12px 0 0 23px;

        &::after {
          content: " ";
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 50px;
          height: 40px;
          background-image: url("../../../assets/images/teach/data-circle.png");
        }

        p {
          color: #fff;
        }

        p.name {
          font-size: 12px;
          margin-bottom: 10px;

          &::before {
            content: " ";
            display: inline-block;
            margin-right: 7px;
            width: 28px;
            height: 28px;
            background-image: url("../../../assets/images/teach/data-time.png");
            background-size: 100%;
            background-color: rgba(255, 255, 255, .21);
            border-radius: 50%;
            vertical-align: middle;
          }
        }

        p.count {
          font-size: 21px;
          padding-left: 6px;
        }

        .unit {
          font-size: 12px;
        }


        &:nth-child(2) {
          background: linear-gradient(90deg, #7854FD 0%, #A5C7FE 100%);

          p.name::before {
            background-image: url("../../../assets/images/teach/data-signin.png");
          }
        }

        &:nth-child(3) {
          background: linear-gradient(90deg, #F8CD8A 0%, #F35D6A 100%);

          p.name::before {
            background-image: url("../../../assets/images/teach/data-number.png");
          }
        }

        &:nth-child(4) {
          background: linear-gradient(90deg, #45DFD6 0%, #119BD1 100%);

          p.name::before {
            background-image: url("../../../assets/images/teach/data-activity.png");
          }
        }
      }
    }

    .table {
      margin-top: 23px;
      overflow: hidden;
    }

    .pagination {
      margin: 25px 0;
    }

    @media screen and (min-width:1250px) {
      .teach-data-wrapper {
        width: 1200px;
      }

      .title {
        font-size: 18px;
        line-height: 48px;

        &::after {
          left: 89px;
        }
      }

      .data-main-content {
        padding: 15px 71px 20px 67px;
      }

      .toolbar {
        line-height: 51px;

        .course-name {
          font-size: 16px;
          width: 500px;
        }

        .select-label {
          font-size: 14px;
        }

        .download-xls {
          font-size: 14px;
        }
      }

      .data-count {
        margin-top: 20px;

        .data-item {
          width: 209px;
          height: 102px;
          padding: 14px 0 0 28px;

          &::after {
            width: 61px;
            height: 48px;
          }

          p.name {
            font-size: 13px;

            &::before {
              width: 34px;
              height: 34px;
              margin-right: 9px;
            }
          }

          p.count {
            font-size: 26px;
          }

          .unit {
            font-size: 14px;
          }
        }

      }


    }
  }
</style>